/*after width goes below then disable*/
@media screen and (max-width: 420px) {
  .navbar-links {
    display: none;
  }
}

nav a {
    text-decoration: none !important;
  }

.navbar-below{
  background-color: rgb(73, 73, 73);
  align-self: auto;
}

.navbar-right{
    position: right;
}

  .nav-link {
    cursor: pointer;
  }
  
  .header {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover; 
    filter: brightness(80%);
    object-position: 100% 0;
  }
  
  .header_bottom{
    height: 10px;
    background-image: linear-gradient(rgb(76, 75, 75), rgb(141, 141, 141));
  }
  
  .skills_wrapper{
    display: block;
    margin: 100px auto 100px auto;
  }
  
  .skills{
    font-size: 30px;
    display: block;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
  }
  
  .skills_label{
    font-family: 'Courier New', Courier, monospace;
    margin-bottom: 30px;
    margin-left: 50px;
  }
  
  .skills_element {
    box-sizing: border-box;
    flex-basis: 0;
    flex: 1 1 0px;
  }
  
  .project_title{
    font-family: 'Courier New', Courier, monospace;
    margin-left: 50px;
  }
  
  .project1_wrapper{
    margin: 50px 100px 100px 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .project1_img {
    width: 100%;
    margin-right: 20px;
    max-width: 480px;
    height: auto;
  }
  
  .project2_img {
    width: 90%;
    margin-right: 20px;
    max-width: 480px;
    height: auto;
  }

  .project3_img {
    width: 100%;
    max-width: 500px;
    height: auto;
  }

  .project4_img {
    width: 100%;
    max-width: 500px;
    height: auto;
  }

  .live_icon{
    margin-bottom: 5px;
    margin-right: 10px;
  }

  .source_icon{
    margin-bottom: 4px;
    margin-right: 10px;
  }

  .project1_text {
    flex: 1 1 60%;
  }
  
  .project1_wrapper .title{
    margin-bottom: 30px;
  }
  
  .p_holder {
    margin: 50px 100px auto 100px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  
  .p_img {
    align-self: center;
    max-width: 100%;
    height: auto;
  }
  
  .p1_btn_live {
    margin: 50px 10px 10px 10px;
  }
  
  .p1_btn_source {
    margin: auto 10px 50px 10px;
  }
  
  .p1_empty {
    height: 300px
  }

  
  .p2_empty {
    height: 300px
  }

  .p_desc {
    width: 100px;
  }
  
  .p3_empty {
    height: 300px
  }

.btn-holder{
  background-color: transparent;
  border: none;
}

.button-27 {
  appearance: none;
  background-color: #000000;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.button-27:disabled {
  pointer-events: none;
}

.button-27:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-27:active {
  box-shadow: none;
  transform: translateY(0);
}

.button-31 {
  background-color: #222;
  border-radius: 4px;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Farfetch Basis","Helvetica Neue",Arial,sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  max-width: none;
  min-height: 44px;
  min-width: 10px;
  outline: none;
  overflow: hidden;
  padding: 9px 20px 8px;
  position: relative;
  text-align: center;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}

.button-31:hover,
.button-31:focus {
  opacity: .75;
}
  
  .aboutMe {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 100px 150px 100px 100px;
  }
  
  .aboutMe .title {
    font-family: 'Courier New', Courier, monospace;
    margin-bottom: 30px;
  }
  
  .aboutMe .text {
    margin: 0px 0px 0px 50px; 
  }
  
  .contactMe {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 100px 150px 100px 100px;
  }
  
  .contactMe .title {
    font-family: 'Courier New', Courier, monospace;
    margin-bottom: 30px;
  }
  
  .contactMe .text {
    margin: 0px 0px 0px 50px; 
  }
  
  .footer {
    width: 100%;
    height: 100px;
    background-color: black;
    border-top: 5px solid gray;
    text-align: center;
    padding: 35px 0 35px;
  }
  
  .footer_holder {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 8%;
  }
  
  .footer_holder .text {
    display: block;
    color: white;
  }
  
  
  